:root {
  --plyr-color-main: theme("colors.secondary");
}

.Video {
  > * {
    @apply top-0 left-0 right-0 bottom-0;
    position: absolute !important;
  }

  .plyr {
    --plyr-color-main: theme('colors.secondary');

    &__poster {
      background-size: cover;
    }
  }

  // Needed because of override in main.css
  button.plyr__control {
    background: theme('colors.secondary');

    svg {
      @apply transition-all duration-500;
    }

    &:hover {
      svg {
        transform: scale(1.25);
      }
    }
  }
}
