.ProductTeaserGrid {
  .primary-box {
    @apply relative bg-cover bg-center bg-gray-100 isolate overflow-hidden;

    background-image: url("/assets/images/bg-solutions-overview-last-box.jpg");

    &::after {
      content: "";

      @apply absolute left-0 top-0 w-full h-full bg-gray-100 opacity-50 -z-10;
    }
  }
}
