.ProductSlider {
  .card {
    @apply isolate relative overflow-hidden;

    background: radial-gradient(59.76% 59.76% at 50% 0%, #ffffff 0%, #d8e4e3 100%);

    &::after {
      content: "";

      @apply bg-gray-300 -z-10 absolute top-0 left-0 w-full h-1/4;
    }

    @media (min-width: 600px) {
      @apply max-w-md xxl:max-w-lg;
    }
  }
}
