.Web2Print {
  .page {
    width: 1050px;
    height: 1486px; // turns out to be exactly one page, if page margins are set to 0
  }

  // padding cant be defined on the whole page
  // We have to define it on every single element...

  .padding-x {
    @apply px-20;
  }

  .padding-top {
    @apply pt-20;
  }

  .right-padding {
    @apply right-20;
  }

  // default tailwind color classes don't work -> they produce rgb()-colors

  .text-white {
    color: white !important;
  }

  .bg-gray-light {
    background-color: #b6c6c4 !important;
  }

  .text-gray-dark {
    color: #283130 !important;
  }

  .bg-blue {
    background-color: #3fcccd !important;
  }

  .bg-gray-dark {
    background-color: #283130 !important;
  }

  .bg-gray-footer {
    background-color: #4f4c4c !important;
  }

  .text-blue {
    color: #3fcccd !important;
  }

  .border-blue {
    border-color: #3fcccd !important;
  }

  .border-gray-light{
    border-color: #b6c6c4 !important;
  }

  .text-gray-light {
    color: #909b9a !important;
  }

   // tailwind classes use custom properties -> they are not supported in pdf rendering
  .translate-y-half{
    transform: translateY(-50%);
  }
}
