.Navigation {
  .country-toggle {
    @apply transition-colors duration-500 hover:text-gray-800;

    &.opened {
      @apply text-gray-800;
    }
  }

  .navigation-toggle {
    @apply transform transition-all duration-500;

    span {
      @apply block top-0 left-0 w-full h-full absolute transform transition-all;

      &::before {
        content: "";
        @apply bg-white block top-1/2 -translate-y-1/2 w-full absolute transform transition-all duration-500 rounded-sm;
        height: 2px;
      }

      &:first-child {
        transform: translateY(-30%);
      }

      &:last-child {
        transform: translateY(30%);
      }
    }

    @media(min-width: theme("screens.lg")){
      &:hover {
        span {
          &::before {
            @apply bg-gray-800;
          }
        }
      }
    }

    &.opened {
      span {
        &::before {
          @apply bg-gray-800;
        }

        &:first-child {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          transform: translateX(100%);
          opacity: 0;
        }

        &:last-child {
          transform: rotate(-45deg);
        }
      }
    }

    @media(min-width: theme("screens.lg")){
      &:hover:not(.opened) {
        span {
          &:first-child {
            transform: translateY(-20%);
          }

          &:last-child {
            transform: translateY(20%);
          }
        }
      }
    }

    @media(min-width: theme("screens.lg")){
      &:hover.opened {
        transform: rotate(90deg);
      }
    }
  }
}

body[data-navigation-fixed="true"] {
  .Navigation {
    position: fixed !important;
  }
}
