@font-face {
  font-family: 'Helvetica Neue';
  src: url('/assets/fonts/helveticaneue-bold.woff2') format('woff2'),
       url('/assets/fonts/helveticaneue-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/assets/fonts/helveticaneue-semibold.woff2') format('woff2'),
       url('/assets/fonts/helveticaneue-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/assets/fonts/helveticaneue-medium.woff2') format('woff2'),
       url('/assets/fonts/helveticaneue-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/assets/fonts/helveticaneue-normal.woff2') format('woff2'),
       url('/assets/fonts/helveticaneue-normal.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Helvetica Neue';
  src: url('/assets/fonts/helveticaneue-extralight.woff2') format('woff2'),
       url('/assets/fonts/helveticaneue-extralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

