.animated-underline {
  @apply inline-block relative text-current;
}

.animated-underline::after {
  @apply absolute block w-full bg-current left-0;
  content: '';
  height: 1px;
  bottom: 1px;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.animated-underline:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}