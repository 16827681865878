.ReferenceSlider {
  @media (min-width: theme("screens.xl")) {
    .slide:nth-of-type(even) {
      @apply bg-secondary block;

      width: calc(33.3333% - 16px) !important;

      .image-container {
        @apply hidden;
      }

      .change-color{
        @apply text-white;
      }
    }
  }
}
