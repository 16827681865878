.ThreeElementsTeaser {
  .card {
    @apply isolate relative overflow-hidden;

    &::after {
      content: "";

      @apply bg-secondary -z-10 absolute top-0 left-0 w-full h-1/3 transition-transform duration-700 origin-top;
    }

    &:hover{
      &::after{
        @apply scale-y-90;
      }
    }

    &.light-bg{
      background: radial-gradient(59.76% 59.76% at 50% 0%, #FFFFFF 0%, #D8E4E3 100%);;

      &::after{
        @apply bg-gray-300;
      }
    }

    @media (min-width: 600px) {
      @apply max-w-md xxl:max-w-full;
    }
  }
}
