.WorldMap {
  .map {
    path {
      @apply fill-gray-1000 transition-colors duration-300;

      &:hover{
        @apply fill-gray-1000/50;
      }
    }

    // reference doesn't have to be a path, it can be a g too.
    .reference {
      @apply fill-secondary transition-colors duration-300;

      &:hover{
        @apply fill-secondary/50;
      }

      path {
        @apply fill-secondary transition-colors duration-300;

        &:hover{
          @apply fill-secondary/50;
        }
      }
    }

    .indicator {
      &::after {
        content: "";

        @apply absolute left-1/2 top-1/2 w-8 h-8 border border-white rounded-full;

        animation: grow 1s linear infinite;

        @keyframes grow {
          0% {
            transform: translate(-50%, -50%) scale(0);
            opacity: 1;
          }

          80% {
            opacity: 1;
          }

          100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 0;
          }
        }
      }
    }
  }

  @media (min-width: theme("screens.lg")) {
    .location-list {
      li {
        @apply isolate;

        --size: 1rem;

        &::before,
        &::after {
          content: "";

          @apply absolute bg-gray-100;
        }

        &::before {
          @apply w-1/2 h-1/2 -z-10;
        }

        &::after {
          @apply -z-10;

          width: var(--size);
          height: var(--size);
        }

        &[data-pin="bottom-left"] {
          transform: translateY(calc(-1 * var(--size)));

          &::before {
            @apply top-1/2 left-0;
          }

          &::after {
            @apply left-0;

            top: calc(100% - 1px);
            clip-path: polygon(0 0, 0% 100%, 100% 0);
          }
        }

        &[data-pin="bottom-right"] {
          transform: translateY(calc(-1 * var(--size))) translateX(-100%);

          &::before {
            @apply top-1/2  right-0;
          }

          &::after {
            @apply right-0;

            top: calc(100% - 1px);
            clip-path: polygon(0 0, 100% 100%, 100% 0);
          }
        }

        &[data-pin="top-right"] {
          transform: translateY(calc(100% + var(--size))) translateX(-100%);

          &::before {
            @apply bottom-1/2 right-0;
          }

          &::after {
            @apply right-0;

            bottom: calc(100% - 1px);
            clip-path: polygon(100% 0, 0 100%, 100% 100%);
          }
        }

        &[data-pin="top-left"] {
          transform: translateY(calc(100% + var(--size)));

          &::before {
            // covering the rounded corner
            @apply bottom-1/2 left-0;
          }

          &::after {
            @apply left-0;

            bottom: calc(100% - 1px);
            clip-path: polygon(0 0, 0 100%, 100% 100%);
          }
        }
      }
    }
  }
}
