.ProductOverview {
  .bg-article-gradient {
    @apply relative;

    &::before {
      @apply absolute -top-4 -left-4 -right-4 sm:-left-6 sm:-top-6 sm:-right-6 lg:-left-10 lg:-top-10 lg:-right-10;
      content: '';
      height: 35%;
      background: linear-gradient(90deg, rgba(215, 227, 226, 0) 0%, rgb(199, 213, 213) 50%, rgba(215, 227, 226, 0) 100%);
    }

    &.LargeArticle {
      &::before {
        content: none;
      }
    }
  }

  &Header {
    @apply relative;

    &::before {
      @apply absolute bottom-0 -left-60 -right-60 top-0 bottom-0 pointer-events-none z-0;
      content: '';
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    }
  }
}