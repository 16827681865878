.TeamQuotes {
  .add-quotes {
    &::before {
      content: "»";
    }
    &::after {
      content: "«";
    }
  }

  .quote{
    @apply relative lg:border-l border-gray-800 lg:px-8 transition-opacity duration-500;

    &::before{
        content: "";

        @apply absolute -left-6 top-0 h-full w-px bg-gray-800 lg:hidden;
    }

    &:not(.swiper-slide-active){
        @apply opacity-50 lg:opacity-100;
    }
  }
}
