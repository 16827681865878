form {
  select {
    @apply appearance-none bg-no-repeat;
    background-position: right 1rem center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjcyNCAwLjM5MDY3MUw3Ljk5OTk2IDYuMTE0NjdMMi4yNzU5NiAwLjM5MDY3MUwwLjM5MDYyNSAyLjI3Nkw3Ljk5OTk2IDkuODg1MzRMMTUuNjA5MyAyLjI3NkwxMy43MjQgMC4zOTA2NzFaIiBmaWxsPSIjM0ZDQ0NEIi8+Cjwvc3ZnPgo=");
  }

  .radio,
  .checkbox {
    @apply relative;

    input {
      @apply hidden;
    }

    label {
      @apply text-base max:text-lg p-0 m-0 flex justify-start items-start transition-opacity duration-300 ease-in-out;
      cursor: pointer;

      &::before,
      &::after {
        @apply block w-8 h-8 shrink-0;
        content: "";
      }

      &::before {
        @apply relative rounded bg-gray-800 mr-3;
      }

      &::after {
        @apply absolute left-0 top-0 ease-in-out duration-300 rounded;
        background: theme('colors.secondary') url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.8499 2.25012L6.24985 12.8501C6.15597 12.9448 6.02817 12.998 5.89485 12.998C5.76153 12.998 5.63374 12.9448 5.53985 12.8501L0.149852 7.46012C0.0551959 7.36623 0.00195312 7.23844 0.00195312 7.10512C0.00195312 6.9718 0.0551959 6.844 0.149852 6.75012L0.849852 6.05012C0.943735 5.95546 1.07153 5.90222 1.20485 5.90222C1.33817 5.90222 1.46597 5.95546 1.55985 6.05012L5.88985 10.3801L15.4399 0.830117C15.6377 0.638333 15.952 0.638333 16.1499 0.830117L16.8499 1.54012C16.9445 1.634 16.9978 1.7618 16.9978 1.89512C16.9978 2.02844 16.9445 2.15623 16.8499 2.25012Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") center no-repeat;
        background-size: 60%;
        content: "";
        opacity: 0;
        visibility: hidden;
        transition-property: visibility, opacity;
      }
    }

    input:checked ~ label {
      @apply text-secondary;

      &::after {
        opacity: 1;
        visibility: visible;
      }
    }

    input:disabled ~ label {
      @apply opacity-30;
    }

    &-custom {
      @apply r-text-base;
    }
  }

  .radio {
    label {
      &::before,
      &::after {
        @apply rounded-full;
      }

      &::after {
        @apply left-2 top-2 w-4 h-4 rounded-full;
        background: theme('colors.secondary');
      }
    }
  }
}