.JobsBannerCta {
  .map {
    .indicator {
      &::after {
        content: "";

        @apply absolute left-1/2 top-1/2 w-10 h-10 md:w-12 md:h-12 lg:w-14 lg:h-14 border border-secondary rounded-full;

        animation: grow 1s linear infinite;

        @keyframes grow {
          0% {
            transform: translate(-50%, -50%) scale(0);
            opacity: 1;
          }

          80% {
            opacity: 1;
          }

          100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 0;
          }
        }
      }
    }
  }

  .map-container{
    @apply relative;

    &::after{
        content: "";

        @apply absolute left-0 top-0 w-full h-full;

        background-color: rgba(26, 34, 33, 0.6);
    }
  }

  .headline{
    strong{
        @apply text-secondary font-medium;
    }
  }
}
