.btn {
  @apply inline-flex items-center justify-start uppercase font-semibold rounded-sm;

  &.primary {
    @apply bg-primary border-2 border-primary text-white;
  }

  &.outlined {
    @apply border-2 border-secondary text-secondary;
  }

  &-lg {
    @apply px-4 py-2 md:px-6 md:py-4 lg:px-7 lg:py-5 text-lg;
  }
}