.HomeHero{
    @apply relative isolate;

    min-height: 65vh;

    @media (min-width: theme("screens.lg")) {
        min-height: 100vh;
    }

    &::after{
        content: "";

        @apply absolute -z-10 pointer-events-none left-0 top-0 w-full h-full bg-gradient-to-t from-black/50 to-black/0;
    }
}