.ModelOverviewSlider {
  @media (min-width: theme("screens.lg")) {
    .slide {
      &.swiper-slide-active {
        margin-right: 16px !important;

        @apply bg-gray-300 text-gray-800;

        li {
          @apply border-gray-400;
        }

        &::after{
            @apply scale-y-100;
        }
      }

      &.swiper-slide-active {
        .image-container {
          @apply h-60;
        }
      }

      &:nth-of-type(1) {
        .image-container img {
          @apply left-1/2 -translate-x-1/2;
        }
      }

      &::after {
        content: "";

        @apply absolute w-full h-32 bg-secondary left-0 top-0 -z-10 origin-top scale-y-0 transition-transform duration-700;
      }


    }

    .image-container {
      @apply transition-all duration-500;

      transition-property: height;
    }
  }
}
