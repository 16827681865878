.Wysiwyg {
  ul {
    @apply pl-6;

    list-style: initial;
  }

  a {
    @apply underline underline-offset-8 md:underline-offset-[0.375rem] text-secondary;
  }

  strong{
    @apply font-bold;
  }
}

.job-content-wysiwyg{
  @apply text-gray-100 text-sm md:text-base lg:text-lg font-normal;

  strong{
    @apply block mt-4 lg:mt-8 font-medium text-base md:text-lg lg:text-xl;
  }

  ul{
    @apply mt-4 md:mt-5 lg:mt-7 text-sm md:text-base space-y-1 md:space-y-2 text-gray-300;

    li{
      @apply flex gap-x-3 items-start;

      &::before{
        content: url("/assets/images/check.svg");

        @apply w-6 h-6 shrink-0;
      }
    }
  }
}

.label-wysiwyg{
  a {
    @apply underline underline-offset-2;
  }
}