$amountOfClasses: 3;
$stagger: 100;

@for $i from 1 through $amountOfClasses {
  .loading-animation-#{$i} {
    animation: fadeIn 300ms ease-out both;
    animation-delay: #{($i - 1) * $stagger}ms;
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
