.r-text-4xl {
  @apply text-xlMobile sm:text-3xl lg:text-4xl;
}

.r-text-3xl {
  @apply text-xlMobile sm:text-2xl lg:text-3xl;
}

.r-text-2xl {
  @apply text-xlMobile sm:text-xl lg:text-2xl;
}

.r-text-xl {
  @apply text-sm sm:text-lg lg:text-xl;
}

.r-text-lg {
  @apply text-sm sm:text-base lg:text-lg;
}

.r-text-base {
  @apply text-xs sm:text-base;
}