.Breadcrumbs {
  max-width: 100vw;
  overflow: hidden;

  .item {
    @apply transition-colors duration-300 whitespace-nowrap lg:whitespace-normal;

    &:hover {
      @apply text-secondary;
    }
  }

  .last-item {
    @apply text-gray-400 whitespace-nowrap lg:whitespace-normal;
  }

  &::before {
    content: "";

    @apply absolute left-0 top-0 w-full h-full bg-gray-900 -z-10 scale-x-0 origin-left transition-transform;
  }

  @media (min-width: theme("screens.lg")) {
    &.on-scroll {
      &::before {
        @apply scale-x-100;
      }
    }
  }
}
