.SolutionsOverview {
  .box:nth-of-type(1) {
    @apply rounded-t-md xl:rounded-none;
  }

  @media (min-width: theme("screens.xl")) {
    .box:nth-of-type(1) {
      @apply rounded-tl-md;
    }
    .box:nth-of-type(4) {
      @apply rounded-tr-md;
    }
  }

  .last-box {
    @apply relative rounded-b-md bg-cover bg-center bg-gray-100 isolate overflow-hidden;

    background-image: url("/assets/images/bg-solutions-overview-last-box.jpg");

    &::after {
      content: "";

      @apply absolute left-0 top-0 w-full h-full bg-gray-100 opacity-50 -z-10;
    }
  }

  .box:not(:nth-of-type(4)) {
    @apply relative;

    &::after {
      content: url("/assets/images/arrow-overview-mobile.svg");

      @apply absolute left-1/2 -bottom-2 -translate-x-1/2 translate-y-1/2 z-10;

      @media (min-width: theme("screens.xl")) {
        content: url("/assets/images/arrow-overview-desktop.svg");

        @apply left-auto bottom-auto right-0 top-1/2 translate-x-1/2 -translate-y-1/2 z-10;
      }
    }
  }
}
