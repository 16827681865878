@tailwind base;
@tailwind components;
@tailwind utilities;

@import "import-fonts";
@import "base";
@import "button";
@import "alpine";
@import "responsive";
@import "components";
@import "admin";
@import "wysiwyg";
@import "swiper";
@import "form";
@import "loader";
@import "references";
@import "gdpr-consent";
@import "editmode";
@import "effects";
@import "products";
@import "dynamic-form";
@import "web2print";
@import "hover";
@import "loading-animation";
