// Prevent layout shifts
.swiper {
  &:not(.swiper-initialized) {
    .swiper-slide {
      &:not(:first-child) {
        @apply hidden;
      }
    }
  }
}

.swiper-pagination-horizontal.swiper-pagination-bullets {
  .swiper-pagination-bullet {
    @apply h-1 w-5 md:w-8 bg-gray-800 rounded-none hover:bg-secondary opacity-100 transition-all duration-500 ease-in-out;

    &-active {
      @apply bg-secondary;
    }

    &:first-child {
      @apply ml-0;
    }
  }
}

.swiper-pagination{
  width: fit-content !important;
}

.swiper-nav{
  .swiper-nav-item{
    @apply transition-colors duration-500 ease-in-out;
  }

  .swiper-nav-item[aria-disabled="true"]{
    @apply text-gray-800;
  }

  .swiper-nav-item[aria-disabled="false"]{
    @apply text-secondary duration-200 hover:text-secondaryHover;
  }

  &.swiper-nav-light{
    .swiper-nav-item[aria-disabled="true"]{
      @apply text-gray-400;
    }
  }
}

.swiper-button-prev:after,
.swiper-button-next:after {
  @apply text-gray-800;
}