.TextTeam {
  background: linear-gradient(180deg, #1a2221 0%, rgba(26, 34, 33, 0) 100%);

  @media (min-width: theme("screens.xl")) {
    background: none;

    @apply relative isolate;

    &::after,
    &::before {
      content: "";

      @apply absolute left-0 w-full h-96 pointer-events-none -z-10;
    }

    &::after {
      @apply bottom-0;

      background: linear-gradient(180deg, rgba(40, 49, 48, 0) 0%, #283130 100%);
    }

    &::before {
      @apply top-0;

      background: linear-gradient(180deg, #1a2221 0%, rgba(26, 34, 33, 0) 100%);
    }
  }
}
