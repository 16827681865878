.dynamic-form {
  form {
    @apply accent-primary;
    max-width: unset !important;

    * {
      font-family: theme("fontFamily.HelveticaNeue") !important;
      font-weight: theme("fontWeight.normal") !important;
      color: theme("colors.gray.300") !important;
      font-size: theme("fontSize.sm") !important;

      @media (min-width: theme("screens.md")) {
        font-size: theme("fontSize.base") !important;
      }
    }

    .formTitle {
      @apply hidden;
    }

    .lp-radioButton {
      @apply block;

      & + .lp-radioButton {
        @apply mt-2;
      }
    }

    div[data-editorblocktype="Divider"] table[role="presentation"] {
      @apply hidden;
    }

    input,
    textarea,
    select {
      @apply px-5 py-3 lg:py-5 mt-1 bg-gray-300;

      border-radius: theme("borderRadius.md") !important;
      color: theme("colors.gray.900") !important;

      &::placeholder {
        color: theme("colors.gray.500") !important;
      }
    }

    select {
      background-image: url("/assets/images/form-select-arrow.svg") !important;

      option {
        color: theme("colors.gray.900") !important;
      }
    }

    div[data-editorblocktype="SubmitButtonBlock"] {
      margin: 0 !important;
      width: 100%;

      @media (min-width: theme("screens.md")) {
        width: fit-content;
      }

      button[type="submit"] {
        @apply bg-primary py-3 px-6 uppercase text-center w-full;

        margin-top: 32px !important;

        color: theme("colors.white") !important;
        font-weight: theme("fontWeight.bold") !important;
        border-radius: theme("borderRadius.sm") !important;
      }
    }

    .ui-menu-item-wrapper{
      color: theme("colors.gray.900") !important;
    }

    div[data-form-block-id] .lookup-behavior{
      background-image: url("/assets/images/form-select-arrow.svg") !important;
      background-position: right 1rem center !important;
    }
  }
}
