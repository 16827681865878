.HistorySlider{

  &::after{
    content: "";

    @apply absolute left-0 top-0 w-full h-80 bg-gradient-to-b from-gray-1000 to-gray-900 -z-50;
  }

  .swiper-overlay{
    @apply relative isolate;

    &::after{
      content: "";

      @apply absolute left-0 bottom-0 w-full h-36 xl:h-80 pointer-events-none z-40 bg-gradient-to-b from-gray-900/0 to-gray-900;
    }
  }

  .main-slider{
    .swiper-slide{
      .headline{
        @apply transition duration-300 ease-in-out;

        transition-property: color, transform;

      }

      &.swiper-slide-active .headline{
        @apply text-secondary;
      }
    }
  }

  .image-slider{
    .swiper-slide{
      @apply transition-opacity duration-200 ease-in-out;

      &:not(.swiper-slide-active){
        @apply opacity-0;
      }
    }
  }
}