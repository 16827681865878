.editmode {
  [class^='aspect-'],
  [class*='aspect-'] {
    >.pimcore_editable_image,
    >a>.pimcore_editable_image {
      @apply absolute top-0 left-0 w-full h-full;

      >img {
        @apply absolute top-0 left-0 w-full h-full object-cover object-center max-w-none max-h-min;
      }

      >.pimcore_editable_droptarget_upload {
        @apply z-20;
      }
    }
  }
}