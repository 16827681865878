.hover-arrow-link {
  svg {
    @apply transition-transform duration-300 ease-in-out;
  }

  &:hover svg {
    @apply translate-x-1;
  }
}

.hover-underline-link {
  @apply relative;

  &::after{
    content: "";

    @apply absolute left-0 bottom-0 w-full bg-current h-px origin-right transition-transform duration-700 scale-x-0;
  }

  &:hover::after {
    @apply scale-x-100 origin-left;
  }
}

.hover-button {
  @apply relative overflow-hidden isolate;

  &::after {
    content: "";

    @apply absolute left-0 top-0 w-full h-full bg-primaryHover origin-right scale-x-0 transition-transform duration-300 -z-10;
  }

  &:hover {
    &::after {
      @apply scale-x-100 origin-left;
    }
  }
}

.hover-button-secondary {
  @apply relative overflow-hidden isolate origin-center transition-transform duration-700;

  &::after {
    content: "";

    @apply absolute left-0 top-0 w-full h-full bg-secondary/5 origin-top scale-y-0 transition-transform duration-300 -z-10;
  }

  &:hover {
    @apply scale-105;

    &::after {
      @apply scale-y-100 origin-bottom;
    }
  }
}

// Blur can be quite expensive - therefore we avoid it on mobile
@media (min-width: theme("screens.lg")) {
  .hover-card {
    @apply relative;

    &::after {
      content: "";

      @apply absolute left-0 -bottom-5 right-0 h-full bg-black/10 -z-10 blur-lg opacity-0 transition-opacity duration-300;
    }

    &:hover::after {
      @apply opacity-100;
    }
  }
}

.hover-picture {
  @apply overflow-hidden;

  img {
    @apply origin-center transition-transform duration-1000;
  }

  &:hover img {
    @apply scale-105;
  }
}

.hover-picture-container {
  picture {
    @apply overflow-hidden;

    img {
      @apply origin-center transition-transform duration-1000;
    }
  }

  &:hover picture img {
    @apply scale-105;
  }
}

.hover-opacity{
  @apply transition-opacity duration-300;

  &:hover{
    @apply opacity-60;
  }
}