.Reference {
  .Products {
    a {
      &::before {
        @apply absolute top-0 left-0 h-full w-0 bg-gray-400 pointer-events-none transition-all duration-700 ease-in-out;
        content: "";
      }

      &:hover {
        &::before {
          @apply w-full;
        }
      }
    }
  }
}