body {
  -webkit-tap-highlight-color: transparent;
  @apply bg-gray-900 font-HelveticaNeue;
}

.containerMax {
  @apply mx-auto container lg:max-w-[calc(100%_-_120px)] max:max-w-screen-max;
}

[x-cloak] { display: none !important; }
